// compliance pack initialization script
// TO DO, this is a temporary index version for testing the consent mode version of this SPA
// please do not replace the original one, instead, merge both when done
import fetchGeoLocation from 'global-utils/fetch-geo-loc';
import fetchPrjInfo from 'global-utils/fetch-prj-info';
import { loadCCIfShould, loadAGIfShould } from 'global-utils/load-conditions';
import { getScriptInfo } from 'global-utils/get-attributes';

import { initGTM } from 'global-utils/GTM-utils';
import {
  deleteCookie,
  isCookiesEnabled,
  shouldSetAgeCookie,
  setBrowserCookie,
  ageGateParam,
  getCookieValue
} from './global-utils/cookie-utils';
import { ready } from './global-utils/doc-state';
import { globalScope } from './global-utils/globals';
import { initCConsent } from './global-utils/init-packages';

if (!isCookiesEnabled()) {
  console.warn('cbgCompPack: cookies are disabled, no cookies will be tracked');
}

if (ageGateParam) {
  if (shouldSetAgeCookie) {
    setBrowserCookie('cbgAge', ageGateParam);
  } else {
    setBrowserCookie('cbgAge', ageGateParam, true);
  }
}

(async () => {
  const geoLocation = await fetchGeoLocation();
  const projectInfo = await fetchPrjInfo();
  const { checkCookies } = getScriptInfo();

  const userLocation =
    geoLocation && geoLocation?.country && geoLocation?.country?.isoCode;
  const userContinent =
    geoLocation && geoLocation?.continent && geoLocation?.continent?.code;

  const reOpenConsent = () => {
    // if the cbgConsent script is not loaded, this reopenConsent function will be called
    // if it is, the reopenConsent inside the cbgConsent App component will be called instead
    initCConsent(projectInfo);
  };

  globalScope.manageCookies = reOpenConsent;

  // only trigger GTM related events if the cookies package is supposed to be used
  if (checkCookies) {
    // add GTM container script to the page head
    initGTM();
  }

  ready(() => {
    loadCCIfShould(projectInfo, userLocation, userContinent);
    loadAGIfShould(projectInfo, userLocation);
  });
})();
