import { getProjectInfo } from 'global-utils/get-attributes';
import { fetchWithTimeout } from 'global-utils/api';
import { API_BASE_URL } from '../config/api';

const { projectId, prjMarket } = getProjectInfo();
const URL = `${API_BASE_URL}/${projectId}/legal-data/${prjMarket}`;
const controller = new AbortController();
const { signal } = controller;

const fetchPrjInfo = async () => {
  if (!projectId || typeof projectId === 'undefined') {
    return null;
  }

  let result;

  try {
    result = await fetchWithTimeout(URL);
  } catch (err) {
    console.error(err);
  }

  return result;
};

export default fetchPrjInfo;
