const cbgCompScript = document.getElementById('cbgCompPack');
const scriptSrc = cbgCompScript && cbgCompScript.getAttribute('src');

const initScriptUrl = scriptSrc;
const baseURL = initScriptUrl.replace('cbgCompPack.js', '');

const getEnvAlias = (baseUrl: string) => {
  if (baseUrl.includes('localhost')) {
    return 'local';
  }
  if (baseUrl.includes('dev')) {
    return 'dev';
  }
  if (baseUrl.includes('tst')) {
    return 'tst';
  }
  if (baseUrl.includes('stg')) {
    return 'stg';
  }
  return 'prd';
};

const APIEnv = {
  local: 'dev',
  dev: 'dev',
  tst: 'tst',
  stg: 'stg',
  prd: 'prd'
};

const APIEnvMedia = {
  local: 'dev',
  dev: 'dev',
  tst: 'tst',
  stg: 'stg',
  prd: 'live'
};

export const BASE_URL = baseURL;
const envAlias = getEnvAlias(baseURL);
const apiEnv = APIEnv[envAlias];
const apiEnvMedia = APIEnvMedia[envAlias];
export const STATIC_ASSETS_URL = `${baseURL}static/`;
export const GEO_API_URL = `https://api.carlsbergwebservices.com/ct/${apiEnv}/ct-geoip-service-be/api/whereami`;
export const POLICIES_BASE_URL = 'https://compliance.carlsberggroup.com';
export const API_BASE_URL = `https://api.carlsbergwebservices.com/ct/${apiEnv}/ct-serv-legal-pol-be/api/projects`;
export const AGEGATE_CONFIG_URL = `age-gateways`;
export const MEDIA_URL = `https://cx-media-fe.${apiEnvMedia}.cx-apps.io`;
