import { getProjectInfo, getScriptInfo } from 'global-utils/get-attributes';
import { getPreferredLang } from 'global-utils/get-language';
import { getPoliciesUrl } from 'global-utils/get-policies-link';
import {
  ageGateParam,
  checkConsentGiven,
  isCookiesEnabled,
  noCookiesParam,
  userGaveConsent
} from './cookie-utils';
import { loadGTM } from './GTM-utils';
import { initAGateway, initCConsent } from './init-packages';

const { GTMId, GTMId4, showAgeGateway, checkCookies } = getScriptInfo();

const { prjMarket, projectId } = getProjectInfo();
const lang = getPreferredLang();
const { cookiesUrl, policiesUrl } = getPoliciesUrl(lang, prjMarket, projectId);

const currentPath = window.location.href.replace(window.location.origin, '');
const currentPage = window.location.href;

const hideCookies = noCookiesParam === 'true' || !checkCookies;

const stripTrailingSlash = (str: string) =>
  str?.endsWith('/') ? str.slice(0, -1) : str;

const isPolicyPage = () => {
  if (!cookiesUrl || !policiesUrl) {
    return false;
  }

  const sanitizeURL = stripTrailingSlash(currentPage);
  const sanitizePath = stripTrailingSlash(currentPath);
  const cookiesPath = stripTrailingSlash(cookiesUrl);
  const privacyPath = stripTrailingSlash(policiesUrl);

  const isPolicy =
    sanitizeURL.startsWith(cookiesPath) ||
    sanitizeURL.startsWith(privacyPath) ||
    sanitizePath.startsWith(cookiesPath) ||
    sanitizePath.startsWith(privacyPath);

  return isPolicy;
};

const shouldLoadCC =
  !hideCookies && isCookiesEnabled() && !userGaveConsent() && !isPolicyPage();

const shouldLoadAG = showAgeGateway && !ageGateParam && !isPolicyPage();

export const loadCCIfShould = (
  projectInfo: {
    entityName: string;
    formalEntityName: string;
  },
  userLocation: string,
  continent: string
) => {
  if (!projectId || !prjMarket) {
    console.error(
      'cbgCConsent:: projectId or prjMarket attribute is missing, could not load cookies banner'
    );
    return;
  }
  if (!GTMId) {
    console.error(
      'cbgCConsent:: GTMId attribute is missing, could not load cookies banner'
    );
    return;
  }
  if (shouldLoadCC) {
    initCConsent(projectInfo, userLocation, continent);
  }
};

export const loadAGIfShould = (
  projectInfo: {
    entityName: string;
    formalEntityName: string;
  },
  userLocation: string
) => {
  if (shouldLoadAG) {
    initAGateway(projectInfo, userLocation);
  }
};
