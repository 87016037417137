import { BASE_URL } from '../config/api';

const getScript = (
  source: string,
  id: string,
  projectInfo?: { entityName: string; formalEntityName: string },
  userLocation?: string,
  userContinent?: string
) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement('script');
    const cgbScripts = document.querySelectorAll('[id^="cbg"]');
    const lastCbgScript = cgbScripts[cgbScripts.length - 1];

    if (id) {
      script.setAttribute('id', id);
    }

    // eslint-disable-next-line func-names
    script.onerror = function () {
      console.error(`Error loading ${this.src}`);
    };

    if (userLocation) {
      script.setAttribute('location', userLocation);
    }

    if (projectInfo && projectInfo.entityName) {
      const { entityName } = projectInfo;

      script.setAttribute('data-entity-name', entityName);
    }

    if (projectInfo && projectInfo.formalEntityName) {
      const { formalEntityName } = projectInfo;

      script.setAttribute('data-formal-entity', formalEntityName);
    }

    if (userContinent) {
      script.setAttribute('continent', userContinent);
    }

    script.src = source;
    script.async = true;
    lastCbgScript.after(script);
  } else {
    console.warn(`script ${id} is already on page`);
  }
};

export const initCConsent = (
  projectInfo: {
    entityName: string;
    formalEntityName: string;
  },
  userLocation: string,
  userContinent: string
) => {
  getScript(
    `${BASE_URL}cbgCConsent.js`,
    'cbgCConsent',
    projectInfo,
    userLocation,
    userContinent
  );
};

export const initAGateway = (
  projectInfo: {
    entityName: string;
    formalEntityName: string;
  },
  userLocation: string
) => {
  getScript(
    `${BASE_URL}cbgAGateway.js`,
    'cbgAGateway',
    projectInfo,
    userLocation
  );
};
